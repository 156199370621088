<template>
	<section class="ProfileView">
		<div class="ProfileView__wrapper">
			<div class="ProfileView__userContent">
				<v-avatar
				@click="openUserAvatarModal()"
				class="UserAvatar"
				size="144"
				>
					<img
					v-if="null === foundUser || null === foundUser.profilePicturePath"
					:src="
						'https://eu.ui-avatars.com/api/?background=random&name=' +
							user.firstname +
							'+' +
							user.lastname
					"
					:alt="user.fullName"
					/>

					<img
					v-else
					:src="currentProfilePresignedUrl"
					:alt="user.fullName"
					/>
				</v-avatar>
			</div>

			<div class="ProfileView__userContent">
				<h1>
					Bonjour, <span>{{ user.fullName }}</span>
				</h1>
			</div>

			<div class="ProfileView__content">
				<MediumButtonSlot @click="toggleChangePassword">
					MODIFIER MON MOT DE PASSE
				</MediumButtonSlot>

				<v-form
				class="ProfileView__form"
				ref="form"
				@submit.prevent="changePassword()"
				v-show="isChangePassword"
				>
					<v-text-field
					ref="field"
					autocomplete
					:append-icon="showActualPassword ? 'mdi-eye' : 'mdi-eye-off'"
					:type="showActualPassword ? 'text' : 'password'"
					@click:append="showActualPassword = !showActualPassword"
					@change="onChangeMDP"
					v-model="form.actualPassword"
					outlined
					clearable
					label="Mot de passe actuel"
					:rules="[$rules.required]"
					:error-messages="errorMessage"
					/>

					<v-text-field
					autocomplete
					:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
					:type="showPassword ? 'text' : 'password'"
					@click:append="showPassword = !showPassword"
					color="#2C0703"
					v-model="form.password"
					outlined
					clearable
					label="Nouveau mot de passe"
					:rules="[
						$rules.required,
						$rules.min8Chars,
						rules.notSamePassword,
						$rules.isPasswordValidFormat,
						rules.samePassword
					]"
					/>

					<v-text-field
					:append-icon="showPasswordRepeat ? 'mdi-eye' : 'mdi-eye-off'"
					:type="showPasswordRepeat ? 'text' : 'password'"
					@click:append="showPasswordRepeat = !showPasswordRepeat"
					color="#2C0703"
					v-model="form.passwordRepeat"
					outlined
					clearable
					label="Confirmer le nouveau mot de passe"
					:rules="[$rules.required, $rules.min8Chars, rules.samePassword]"
					/>

					<div class="formButtons">
						<HighButtonSlot
						:_disabled="!isPasswordValid"
						_type="'submit'"
						>
							Changer le mot de passe
						</HighButtonSlot>
					</div>
				</v-form>
			</div>
		</div>

		<Modal
		v-if="isUserAvatarModalOpen"
		title="Modifier la photo de profil"
		@close="closeUserAvatarModal()"
		@validate="savePicture()"
		>
			<div>
				<v-img
				class="profilePic"
				v-if="null !== url"
				target="_blank"
				:src="url"
				/>

				<div v-else>
					<img
					v-if="currentProfilePresignedUrl === null"
					class="imgPlaceholder profilePic"
					:src="
						'https://eu.ui-avatars.com/api/?background=random&name=' +
							user.firstname +
							'+' +
							user.lastname
					"
					:alt="user.fullName"
					/>

					<img
					class="profilePic"
					v-else
					:src="currentProfilePresignedUrl"
					:alt="user.fullName"
					/>
				</div>

				<div class="fileInput mt-2">
					<div>
						<div
						:class="
							null !== image
								? 'file_input_personalized active'
								: 'file_input_personalized'
						"
						@click="selectFile"
						>
							<v-icon
							v-if="null === image"
							medium
							center
							color="#2c0703"
							>
								mdi-paperclip
							</v-icon>
						</div>
					</div>

					<input
					type="file"
					ref="fileInput"
					accept="image/png, image/jpeg, image/jpg"
					class="d-none"
					@change="updateFile"
					/>

					<div>
						<MediumButtonSlot @click="selectFile">
							{{ null === image ? $t("add_pictures") : "Modifier la photo" }}
						</MediumButtonSlot>
					</div>

					<LowButtonSlot
					class="ml-1"
					@click="deleteImage()"
					>
						Supprimer la photo
					</LowButtonSlot>
				</div>
			</div>
		</Modal>
	</section>
</template>

<script>
import auth from "@/service/auth";
import Modal from "@/components/slots/modal/Modal.slot.vue";

export default {
	name: "ProfileView",
	components: {Modal},
	data(){
		return {
			currentProfilePresignedUrl: null,
			image: null,
			url: null,
			isUserAvatarModalOpen: false,
			isChangePassword: false,
			showActualPassword: false,
			showPassword: false,
			errorMessage: null,
			showPasswordRepeat: false,
			rules: {
				samePassword: () => {
					if(!this.form.passwordRepeat || !this.form.password) return true;
					return (
						this.form.passwordRepeat === this.form.password ||
            "Les mots de passes doivent être identiques"
					);
				},
				notSamePassword: value =>
					value !== this.form.actualPassword ||
          "Le mots de passe actuel et le nouveau ne doivent pas être identiques"
			},
			form: {
				actualPassword: "",
				password: "",
				passwordRepeat: ""
			},
			foundUser: null,
			isvalid: false
		};
	},
	computed: {
		isPasswordValid(){
			return this.form.password == this.form.passwordRepeat;
		},
		user(){
			const userData = auth.decodeToken();
			return {
				firstname: userData.firstname,
				lastname: userData.lastname,
				fullName: `${userData.firstname} ${userData.lastname}`,
				userId: userData.userId,
				username: userData.username
			};
		}
	},
	methods: {
		async refreshViewInfos(){
			await this.getUser();
			if(null === this.foundUser.profilePicturePath) return (this.currentProfilePresignedUrl = null);
			this.currentProfilePresignedUrl = await this.getDocument(
				this.foundUser.profilePicturePath
			);
		},
		async getDocument(path){
			const document = await this.$api.documents.download(path);

			return window.URL.createObjectURL(document);
		},
		async getUser(){
			this.foundUser = await this.$api.users.findById(this.user.userId);
		},
		async savePicture(){
			let formData = new FormData();
			formData.append("file", this.image);

			await this.$api.users.uploadAvatar(this.user.userId, formData);
			this.isUserAvatarModalOpen = false;
			this.refreshViewInfos();
		},
		async deleteImage(){
			this.image = null;
			this.url = null;

			await this.$api.users.patch(this.user.userId, {
				profilePicturePath: null
			});
			this.refreshViewInfos();
			this.isUserAvatarModalOpen = false;
		},
		openUserAvatarModal(){
			this.isUserAvatarModalOpen = true;
		},
		closeUserAvatarModal(){
			this.isUserAvatarModalOpen = false;
		},
		toggleChangePassword(){
			if(this.isChangePassword){
				this.isChangePassword = false;
			}
			else {
				this.isChangePassword = true;
			}
		},
		cancelPasswordChange(){
			this.showActualPassword = false;
			this.showPassword = false;
			this.showPasswordRepeat = false;
			this.form = {
				actualPassword: "",
				password: "",
				passwordRepeat: ""
			};
			this.isChangePassword = false;
		},
		async changePassword(){
			if(!this.$refs.form.validate()) return;
			try {
				await this.$api.users.editPassword(this.user.userId, {
					oldPassword: this.form.actualPassword,
					plainPassword: this.form.password
				});
				this.showPasswordChanged = true;
				this.showPasswordMismatch = false;

				!this.$refs.form.resetValidation();

				this.cancelPasswordChange();
			}
			catch (e){
				this.errorMessage = "Le mot de passe actuel est incorrect";
			}
		},
		onChangeMDP(){
			this.errorMessage = null;
		},
		selectFile(){
			this.$refs.fileInput.click();
		},
		updateFile(e){
			const files = e.target.files;

			if(null !== files && 0 < files.length){
				this.image = files[0];
				this.url = window.URL.createObjectURL(new Blob([this.image]));
			}
		}
	},
	mounted(){
		this.refreshViewInfos();
	}
};
</script>

<style lang="scss">
@import "../assets/scss/_variables.scss";

.ProfileView {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  .UserAvatar {
    cursor: pointer;
  }

  .file_input_personalized__filename {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .fileInput {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .profilePic {
    margin-bottom: 20px;
    height: 300px;
    width: 300px;
    border-radius: 50%;
    border: 2px solid #ddd;
    margin-left: 50%;
    transform: translateX(-50%);
  }

  &__userContent {
    margin: 50px 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      font-size: 36px;
      font-weight: 500;
      letter-spacing: 0.15px;
      span {
        color: $dark;
      }
    }
    img {
      border: 12px $dark solid;
    }
  }
  &__form {
    margin-top: 25px;
    position: relative;

    .formButtons {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      * {
        margin: 0 30px;
      }
    }
    .confirmNotifBad {
      color: red;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 30px 0;
    }
    .confirmNotifGood {
      color: green;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 30px 0;
    }
  }
  &__pwHandle {
    position: relative;
    top: -20px;
    span {
      font-size: 12px;
    }
  }
  &__confirm {
    // @extend %confirmPopin;
  }
  &__wrapper {
    // @extend %viewWrapper;
  }
  &__titleBox {
    background: grey;
    border-bottom: 1px #e8e8e8 solid;
    height: 80px;
    margin: 0;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h2 {
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0.15px;
    }
  }
  &__content {
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    & > *:last-child {
      width: 100%;
      max-width: 100%;
    }
    &__pic {
      & > *:last-child {
        margin-left: 20px;
      }
    }
  }
}
</style>
